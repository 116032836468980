<template>
  <section>
    <div class="content-header">
      <h2>Jewish identity and antisemitism</h2>
    </div>
    <div class="content-wrapper">
      <p>This pillar is an opportunity to share key moments in history that have shaped the identity and experiences of Jewish people in Canada and many Jewish students. The breadth and depth of the Jewish identity is shaped by religion, ethnicity, history and culture. Judaism and its various denominations (e.g. Conservative, Modern Orthodox, Orthodox, Reconstructionism, Reform), and the differing philosophical underpinnings and interpretations of Jewish law are not explained in depth. Yet, it’s worth noting that religion underlies Jewish traditions and values, even if religion is not very important in a Jewish person’s life today; as is the case for one in three Canadian Jews surveyed in 2018. Jewish values shape attitudes to education and family, and practices such as the celebration or observance of Jewish holidays. </p>
      <p>An Ivey Honours Business Administration (HBA) student shares the essence of her Jewish identity: </p>
      <div class="panel">
        <p><em>“Growing up in a traditional Jewish home provided me with a strong connection to Judaism, as well as a passion for my heritage, culture, and history. All four of my grandparents are Holocaust survivors and it was important to them that future generations have a deep sense of pride as Jews and an unwavering connection to Zionism and a home for the Jewish people. These go hand in hand. For me, none exist without the other.” </em></p>
      </div>
      <p class="pt-3">This pillar goes on to explore the following: </p>
      <ul>
        <li>Jewish immigration to Canada </li>
        <li>Antisemitism and the Holocaust </li>
        <li>Zionism: A home for the Jewish people </li>
      </ul>
      <div class="accordion">
        <accordion
          headerText="Jewish immigration to Canada "
          accordionID="collapse01"
        >
          <p>Jewish immigrants, primarily from Western Europe, began arriving on North American shores by the middle of the eighteenth century. However, as proclaimed by law, New France was restricted to Catholics only. Some Jews converted to Catholicism while others chose to settle further south in British occupied territory. The first significant arrival of Jews in Canada was a direct result of the establishment of the British Forces. Canada’s first synagogue was opened in 1768 in Montreal where the first Jewish community was established. After the fall of New France, Jews began to settle openly in Canada as British rule resulted in more religious tolerance. </p>
          <p>By 1881, Canadian Jews totalled just under 3,000. This number increased to 125,000 by 1921. The confluence of two major factors gave rise to this increase. First, eastern European Jews were fleeing pogroms incited by the Russian government. Pogroms were violent mob attacks on Jewish life and property. For example, from 1881-1882, 200 such attacks left 100,000 Jews homeless. Second, Canada was experiencing an economic boom (often characterized as the “Golden Years” or “Laurier Boom”) and in need of immigrant labour. Although British immigrants were preferred, Canada’s High Commissioner to London was influenced by the perception that eastern European Jews were well educated and possessed a variety of occupational skills. It’s worth noting that even in these pre-WWII years, Jewish immigration did not exceed 4% of total Canadian immigration. </p>
          <p>Many Jewish refugees in Canada the 1880s initially lived in extreme poverty, due to “sweat shop” like working conditions and making a lot of sacrifices to create the possibility of bringing more Jewish refugees within their communities. Later, and once settled in Canada, Jews held occupations as retailers, wholesalers, and peddlers. To continue practicing their Jewish faith and traditions, synagogues and religious institutions were founded amongst the expanding Jewish communities in Canada. </p>
          <p>Unfortunately, the economic boom ended with the onset of the Great Depression, while discriminatory and restrictive immigration policies increasingly took hold in Canadian policy-making. By the end of WWII, despite the incredible humanitarian need, Canada imposed a “none is too many” immigration policy in which the government significantly curtailed asylum for European Jews. Canada only absorbed 5,000 Holocaust survivors. The political sentiment partially reflected public opinion; a poll taken in 1946 found 49% of Canadians opposed Jewish immigration. </p>
          <p class="pb-2">The post-WWII Jewish communities in Canada underwent significant transitions. The quotas and restrictions of the 1920s and 1930s were slowly dismantled, and had mostly disappeared by the 1970s and 1980s. Some of these restrictions are captured in Irving Abella’s writings: </p>
          <div class="panel">
            <p><em>“There were restrictive covenants on properties preventing them from being sold to Jews. As well, many clubs, resorts, and beaches were barred to Jews. Signs warning "No Jews or Dogs Allowed" or "Christians Only!" could be found on Halifax golf courses and outside hotels in the Laurentians. The same was true for the cottage areas in Ontario, the lake country of Manitoba, and the vacation lands of British Columbia.” </em></p>
          </div>
          <p class="pt-3">Today, Canadian Jews constitute approximately one percent of the Canadian population. As detailed in the 2018 Survey of Jews in Canada (An Environics Institute survey, in partnership with UofT and York University), the basis of identity for most Canadian Jews today is “less about religion than about culture, ethnicity, or a combination of culture, ethnicity, and religion. For Jewish-Canadians, their Jewish identity is often strongly connected with the land of Israel in many ways including cultural, religious, or ancestral connections. </p>
        </accordion>
        <accordion
          headerText="Connection to Israel"
          accordionID="collapse02"
        >
          <p> Canadian Jews have a strong connection to Israel. A large majority express an emotional attachment to Israel and have spent time in the country. Eight in ten have visited Israel at least once and have done so an average of five times to date. One in six report having lived in Israel for six months or more. Travel to Israel is most prevalent among Jews who are Orthodox/Modern Orthodox, but it’s common across the population, especially among Jews under 45 years of age and those with a post-graduate degree. </p>
          <p> While Jews may share a connection to Israel, they do not agree when it comes to the politics of the region. Canadian Jews are divided in their views about the Israeli government’s commitment to a peace settlement with the Palestinians and the building of settlements on the West Bank in terms of their legality and impact on the security of Israel. Critical opinions of Israel are most evident among younger Jews, and those who are Reform or unaffiliated. </p>
          <p> Source: 2018 Survey of Jews in Canada (Brym et al, 2020) </p>
          <p> In addition to the connection to Israel, the Jewish identities in Canada are shaped by Jewish educational opportunities. Most Canadian Jews have participated in one or more types of Jewish education while growing up, such as overnight summer camp, Hebrew school or Sunday school. This includes members of the Ivey community who have themselves had the experience of coming up through Jewish education in Canada, one of whom shares their experience: </p>
          <div class="panel">
            <p><em>“I consider myself very lucky that I grew up in a tight-knit Jewish community. Both of my next-door neighbours were Jewish, I attended a private Jewish day school from nursery to grade twelve, and I spent my summer at a Jewish sleepaway camp. Growing up like this, I considered certain things to be “givens” – non-negotiable facts that shaped my identity and experience as a practicing Jew. For example, I would never have attended a school-related meeting on a Friday night after sunset or a Saturday before sunset, as those 25 hours constitute Shabbat. Before university, I would never have imagined scheduling a meeting on Shabbat. Obviously, the rest of the world doesn’t abide by the Jewish calendar and unfortunately, I felt compelled to bend some of my Jewish beliefs and practices to accommodate convention. </em> </p>
            <p><em>However, I still thought that some realities about the Jewish identity, experience, and history were universally understood and would be respected. Sadly, I have been proven wrong time and again. During a group project, a peer, who I considered a friend, said to me, “I don’t know why people make such a big deal about the Holocaust. It happened almost a hundred years ago.” In the summer of 2021, I explained to several peers why the misinformation they were spreading on social media was antisemitic. They denied it—they claimed they could never be antisemitic. </em> </p>
            <p><em>I trust my 2SLGBTQQIA+ friends when they identify something as homophobic. I support my Muslim friends when they experience Islamophobia. Why am I, a Jew, not believed or supported when I face antisemitism? Why do my peers dictate what is and is not a threat to my identity?” </em> </p>
          </div>
          <p class="pt-3">Although there is often pressure to conform to “societal norms” based on the institutional expectations, for example in secular post-secondary education or in the workforce, it’s important to recognize that these “norms” often pose barriers for individuals whose ways of lives are deeply connected to their religion and traditions. As we think about practicing equity, we also need to consider how we create space for everyone’s way of life. </p>
        </accordion>
        <accordion
          headerText="Antisemitism "
          accordionID="collapse03"
        >
          <p>As documented in the 2018 Survey of Jews in Canada, “Anti-Semitism has a long history in Canada and continues to be experienced among Jews today. Close to four in ten Canadian Jews report having experienced discrimination in the past five years due to their religion, ethnicity/culture, sex and/or language.” </p>
          <p>Animosity towards Jewish people has existed for thousands of years with the earliest recorded persecution dating back to 270 BCE. Over the centuries, anti-Jewish sentiment and hostility has been recurrent, linked to religious, racial, economic and political dimensions of the Jewish-identity. </p>
          <p>As time went on, hatred towards the Jewish people escalated; stereotypes morphed into propaganda, and propaganda into violence. While the term <em>antisemitism</em> was only coined in 1879, it is evidenced in numerous historical examples of expulsions, mass murders and forced conversions prior to that year. </p>
          <p>The insidious nature of antisemitism ultimately culminated in one of the greatest tragedies in human history, the Holocaust. The genocide of the Jews, which began in 1939, was made possible by the rise of fascism in a relatively democratic country. The <em>National Socialist German Workers’ Party</em> (Nazi) were elected in 1933 and led by Adolf Hitler. Although the Nazis never gained a majority vote, a prolonged recession stemming from WWI enabled the Nazi party to gain support for positioning the Jewish population as the scapegoat of all local and national problems. </p>
          <p>The premise of the Nazi party was to cleanse the world of inferior races to produce a genetically superior German race. Although Jews were principally targeted, many other groups were also targeted under the purity laws, including European Roma, disabled individuals, homosexuals and Slavic people. Laws were enacted that isolated Jews from society whether it was by curfew and restricted access in public areas and to schools. Eventually, Jews were propelled into poverty as they were not permitted to engage in any business activities. The realization that former neighbours, “friends and acquaintances” were appropriating property and upholding antisemitic laws in line with the Nazis was a betrayal that created lifelong scars for those who survived the Holocaust. </p>
          <p>It’s important to know that the past is not separable from present day antisemitism in Germany. Over the past few months, there have been investigative reports sharing detailed accounts of far-right extremists inside the German military and police forces. The far-right extremist group believes in a Jewish conspiracy to destroy the German nation. As a result, they planned several assassinations that were aimed to bring down the democratic government in an attempt to reclaim Germany for non-Jewish and non-racialized Germans. Today’s far-right extremists in Germany stem from a conspiracy theory, known as the “stab in the back legend” which became a keystone of Nazi propaganda—in which the civilian leaders were portrayed as the puppets of leftists and Jews. After WWII, many newly unemployed soldiers in Germany joined paramilitary groups that eventually grew to support the rise of neo-Nazism. This is where the country finds itself today. This far-right group of extremists have gained enough power, personnel and resistance to establish a political party, enlist individuals into the German military and recruit police officers. This rise in contemporary antisemitism and neo-Nazism in Germany is a critical reminder the antisemitism has not been eradicated. </p>
          <p>It’s also important to note that while secondary schools may have included curriculum about the Holocaust, the content presented here help provide a more contextualized understanding of why we are where we are today as a society in regard to antisemitism. </p>
        </accordion>
        <accordion
          headerText="The Final Solution "
          accordionID="collapse04"
        >
          <p>The unique nature of the Holocaust was that it was led by the state that controlled the allocation of resources while also enforcing the playing rules. The Nazis had been working on answering the Jewish question: how do we eradicate the Jews of Europe? The final solution was a culmination of multiple tactics employed between 1939-1945. Once the Nazi invasion began, ghettos were created to cluster the Jews into one area to make it easy to transport them to the death camps. Aside from the concentration camps, other methods to eliminate Jews more efficiently included the Einsatzgruppen which were defined as mobile killing units. These units obliterated entire towns killing every single Jewish man, woman, and child regardless of circumstance. Ultimately, two thirds of all Jewish people living in Europe were murdered within a 6-year span. Of the 6 million Jews murdered, 1.5 million were children. Many survivors say that being liberated was even harder than the war itself as reality began to set in of the gravity of the situation and what was waiting on the other side. When Soviet and American forces liberated Europe in May 1945, there were only 250,000 survivors left. </p>
        </accordion>
        <accordion
          headerText="Israel & Zionism "
          accordionID="collapse05"
        >
          <p>The connection between the Jewish people and the land of Israel has existed for nearly 4,000 years. In the Jewish bible (Torah), G-d promises the land to the Jewish people who then settled and developed in the land for centuries to come. Prior to the establishment of the State of Israel in 1948, diverse populations lived within the land under various rulers including the Romans, Ottomans, and the British. The international community began recognizing the need for a Jewish state (Balfour Declaration (1917), the Paris Peace Conference (1919), and the League of Nations (1922)). Amongst Jews, the support for a Jewish state in the land of Israel formulated into a widespread movement referred to as Zionism which solidified in the late 19th century. The support for an independent Jewish state intensified with the beginning of Nazi rule in 1933. The partition plan was proposed in 1947, outlining both a Jewish and Arab state. On May 14th, 1948, the State of Israel was established and continued to absorb Jewish refugees from Europe, the Middle East, and North and East Africa. </p>
          <p>Given that one-third of the European Jewry was wiped out within a span of six years, Israel was the light at the end of the tunnel for the Jews who had survived the Holocaust. Since its establishment, Israel has fought several wars to protect its sovereignty. Today, 47% of world Jewry resides in Israel. The belief in a Jewish homeland within Israel continues to light the flame of unity of Jewish communities in the diaspora. Initially, Zionism was a movement to re-establish a Jewish nation in the ancestral homeland of modern day Israel. After 1948, this movement transitioned into supporting the development and protection of the state of Israel. </p>
          <p>Currently, there is often discourse on the difference between antisemitism and antizionism. Although some Jewish people believe that the two are not mutually exclusive, it’s important to note that the intersections between the two is where individuals sometimes misinterpret and fall into the tropes of antisemitism or antizionism. Antisemitism is hostility, prejudice or violence towards Jewish people on the basis of religious, cultural, ethnic, lingual, political or economical aspects of their identities. Hostility towards the political affiliations of Jewish people is often conflated with antizionism. </p>
          <p class="">An Ivey Honours Business Administration (HBA) student shares:</p>
          <div class="panel mb-3">
            <p>“I went to a High School where I was the only Jewish student in my grade. I felt alienated in those moments when derogatory comments were made. I remember when a fellow classmate made the blanket statement that Jews were terrorists and another instance where a peer commented that “6,000,000 was not enough”. I felt that again this past May when similar comments were made on social media. Government officials who are democratically elected in Israel, make decisions that they believe are in the best interest of their country. I may or may not support every decision made by the Israeli government, yet I am made to feel responsible because I am a Jew. Is every Canadian in agreement and responsible for every single decision our Prime Minister or Premier makes?"</p>
          </div>
          <p>Antizionism is a prejudice against the right of the Jewish people to a homeland in the State of Israel. It may be motivated by or result in antisemitism, or it may create a climate in which antisemitism becomes more acceptable depending on how people engage in the debate. Higher education is a place for ideas to be debated and learned from. However, one cannot and should not debate the existence of an identity or a person’s place of origin while engaging in these discussions.</p>
          <p>Antizionists’ criticisms often engage in the disapproval of the Israeli government’s use of force and its policies. However, many have used this discourse as an opportunity to engage in Anti-Israel demonstrations that were fueled by antisemitic tropes and stigmas. During the Palestine/Israel crisis in May 2021, antisemitic incidents climbed over 468% worldwide. #Hitlerwasright was trending on twitter and swastikas began appearing on cars and Jewish institutions. </p>
          <p>Holding a government accountable for its actions and denying its existence are two very different things. </p>
          <p>As post-secondary institutions, we are not blind to the nuances that exist within the interactions of geopolitical affiliations and nationality and religious identities. Fostering a safe environment means understanding and protecting the expression of various identities, including monotheistic religious identities. </p>
        </accordion>
      </div>

    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    Accordion
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
